import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { CSVLink } from 'react-csv';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import DateRangePicker from '../DateRangePicker';
import { subDays } from 'date-fns';
import MUIModal from '../../common/MUIModal';
import { FormProvider, RHFSelectField } from '../../common/hook-form';
import { formatDate, supportTicketStatus } from '../../helpers/UtilityHelper';
import { apiConstants, post } from '../../common/constants';

const ExportTicketData = ({ show, handleClose, merchantOid }) => {
    // console.log(merchantOid);

    const initialDateRange = [{
        endDate: new Date(),
        startDate: subDays(new Date(), 30),
        key: 'selection'
    }];
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [csvData, setCsvData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const methods = useForm({
        defaultValues: {
            status: '',
        }
    });
    const { handleSubmit } = methods;

    useEffect(() => {
        if (!show) {
            setDataReady(false); // Reset data readiness when the modal is closed
            setIsLoading(false);
        }
    }, [show]);

    const onSubmit = async (filters) => {
        setErrorMessage('');
        const startDate = dateRange[0].startDate;
        const endDate = dateRange[0].endDate;
        const dateDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

        if (dateDifference > 31) {
            setErrorMessage('The selected date range should not exceed 31 days.');
            return; // Exit the function early
        }

        setIsLoading(true);
        setDataReady(false);

        const allData = [];
        const currentDay = new Date(dateRange[0].startDate);

        // Utility function to create a delay
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        // Loop through each day in the date range
        while (currentDay <= dateRange[0].endDate) {
            const payloadBase = {
                start_date: formatDate(currentDay, "yyyy-MM-dd"),
                end_date: formatDate(currentDay, "yyyy-MM-dd"),
                merchantOid: merchantOid,
                page: 1, // Start with page 1
            };

            if (filters.status) {
                payloadBase.status_list = [filters.status];
            }

            try {
                // Initial request to get data for the specific day
                let response = await post(`${apiConstants.BASE_LOCAL}${apiConstants.FILTER_FRESHDESK}`, payloadBase);
                const parsedRes = JSON.parse(response.data?.responseObj);
                const totalCount = parsedRes.total; // This should be provided by the API
                const totalPages = Math.min(Math.ceil(totalCount / 30), 10); // Handle up to 10 pages max
                let dayData = parsedRes.results; // Assuming the data is in 'results' key

                // Fetch subsequent pages for the day
                for (let page = 2; page <= totalPages; page++) {
                    payloadBase.page = page;
                    response = await post(`${apiConstants.BASE_LOCAL}${apiConstants.FILTER_FRESHDESK}`, payloadBase);
                    const parsedRes1 = JSON.parse(response.data?.responseObj);
                    dayData = dayData.concat(parsedRes1.results); // Concatenate records from each page
                }

                // Accumulate data from all days
                allData.push(...dayData);
            } catch (error) {
                console.error(`Error fetching data for date ${formatDate(currentDay, "yyyy-MM-dd")}:`, error);
                setDataReady(false);
                setIsLoading(false);
                return; // Exit the function on error
            }

            // Delay for 1 second before moving to the next day
            await delay(500);

            // Move to the next day
            currentDay.setDate(currentDay.getDate() + 1);
        }

        // Convert all accumulated data to CSV format
        const csvExportData = allData.map(item => ({
            id: item?.id,
            awb: item?.custom_fields?.cf_awb_number387695,
            category: item?.custom_fields?.cf_category,
            subCategory: item?.custom_fields?.cf_sub_category,
            user: item?.custom_fields?.cf_user_name,
            status: supportTicketStatus.find((val) => val.value === item?.status)?.label,
            dateCreated: formatDate(item?.created_at, "yyyy-MM-dd"),
            lastUpdate: formatDate(item?.updated_at, "yyyy-MM-dd"),
            resolvedBy: item?.custom_fields?.cf_closed_by_name || item?.custom_fields?.cf_resolved_by_name,
            description: item?.description_text,
        }));

        setCsvData(csvExportData);
        setDataReady(true);
        setIsLoading(false);
    };



    return (
        <MUIModal open={show} maxWidth="lg" title="Export Tickets Data" handleClose={handleClose}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {errorMessage && (
                            <div style={{ color: 'red', marginTop: '10px' }}>
                                {errorMessage}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={2.6}>
                        <DateRangePicker
                            defaultRange={dateRange}
                            maxDate={new Date()}
                            onChange={(range) => setDateRange(range)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <RHFSelectField
                            label="Status"
                            name="status"
                            options={supportTicketStatus.map((val) => ({
                                value: val.value,
                                label: val.label
                            }))}
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        {!dataReady && (
                            <LoadingButton loading={isLoading} type="submit" variant="contained">
                                Fetch Data
                            </LoadingButton>
                        )}
                        {/* <LoadingButton loading={isLoading} type="submit" variant="contained">
                            Fetch Data
                        </LoadingButton> */}
                        {dataReady && (
                            <CSVLink
                                data={csvData}
                                headers={[
                                    { label: "Ticket ID", key: "id" },
                                    { label: "AWB", key: "awb" },
                                    { label: "Category", key: "category" },
                                    { label: "Sub Category", key: "subCategory" },
                                    { label: "Created By", key: "user" },
                                    { label: "Current Status", key: "status" },
                                    { label: "Created Date", key: "dateCreated" },
                                    { label: "Last Updated Date", key: "lastUpdate" },
                                    { label: "Resolved By", key: "resolvedBy" },
                                    { label: "Description", key: "description" }

                                ]}
                                filename={"ems_export.csv"}
                                className="btn btn-success"
                                style={{ marginLeft: '10px' }}
                            >
                                Download CSV
                            </CSVLink>
                        )}
                    </Grid>
                </Grid>
            </FormProvider>
        </MUIModal>
    );
};

export default ExportTicketData;
